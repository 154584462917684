body {
  margin: 0;
  padding: 0;  
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  color: #262626
}
.body-background {
  background-image: url('../public/images/form-bg.png');
  background-size: cover;
  background-position: center;
  background-color: #4451a3;
  min-height: 100vh;
}

.signup-form {
  max-width: 505px;
  margin: 0 auto;
  padding: 35px 30px;
  border: 0.5px solid #878787;
  box-shadow: 0px 4px 64px 0px #0000000D;
  border-radius: 10px;
  background-color: #fff;
}

.logo img {
  height: auto;
}
/* Loader container style */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  /* Align text below the loader */
    background-color: rgba(0, 0, 0, 0.5);  /* Transparent background */
    z-index: 9999;
}

/* Loader style */
.loader {
    border: 7px solid #f3f3f3;
    border-top: 8px solid #4451a3;  /* Color of the spinner */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;  /* Spin animation */
    margin-bottom: 10px;  /* Space between the spinner and text */
}

/* Text below the loader */
.loading-text {
    color: #fff;  /* White text color */
    font-size: 16px;
    font-weight: bold;
    animation: fadeInText 2s ease-in-out infinite;  /* Text fade-in animation */
}

/* Spin animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Text fade-in animation */
@keyframes fadeInText {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}


.form-welcome-heading h2 {
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 45px;
  color: #525252;
}

.form-heading h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  color: #161616;
}

.form-heading p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #161616;
}

#wrapper {
  display: flex;
}

#wrapper #content-wrapper {
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
}

#wrapper #content-wrapper #content {
  flex: 1 0 auto;
  padding: 20px;
}

.blut-light {
  background: #7AABDC;
}

/* ********************* Sidebar CSS code START from here  ********************* */
.sidebar {
  width: 250px;
  min-height: 100vh;
  background-color: #364182;
  min-width: 250px;
  padding-bottom: 30px;
}

.sidebar-top {
  padding: 30px 20px 20px 20px;
}

.sidebar .logo img {
  margin-bottom: 40px;
  max-width: 180px;
}

#dropdownMenuButton1 {
  /* min-height: 48px; */
  border-radius: 0;
  padding: 12px 65px 12px 15px;
  position: relative;
  border: 0;
}

#dropdownMenuButton1::after {
  content: "";
  height: 50px;
  width: 1px;
  background: #333;
  position: absolute;
  top: -1px;
  right: 48px;
}

#dropdownMenuButton1 .chev-down {
  background-image: url('../public/images/chev-down.png');
  width: 48px;
  height: 48px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

#dropdownMenuButton1:hover,
#dropdownMenuButton1:focus {
  background-color: #0050E6;
}

.sidebar-links {
	padding: 0;
	margin: 20px 0 0 0;
	list-style: none;
	overflow-y: auto;
	min-height: 320px;
}

.sidebar-links li {
  font-size: 16px;
}

.sidebar-links li a {
  display: block;
  padding: 10px 20px;
  color: #F8F8F8;
  text-decoration: none;
}

.sidebar-links li a svg {
  margin-right: 5px;
}

.sidebar-links li:hover a,
.sidebar-links li.active a {
  background: #C7CBE3;
  color: #262626;
  font-weight: 500;
}

.sidebar-links li a.active {
  background: #C7CBE3;
  color: #262626;
  font-weight: 500;
}

.sidebar-links li:hover a svg path[fill],
.sidebar-links li.active a svg path[fill] {
  fill: #161616;
}

.sidebar-links li a.active svg path[fill] {
  fill: #161616;
}

/* .sidebar-links li a::before {
	content: "";
	background-image: url('../images/icons/Document.svg');
	width: 16px;
	height: 16px;
	display: inline-block;
	margin-right: 10px;
	vertical-align: middle;
} */


.sidebar .user-name {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.logout-link {
  color: #BDD5EE;
  font-size: 16px;
  font-weight: 600;
  background: none;
  border: 0;
}
/* ********************* Sidebar CSS code END here  ********************* */
.page-heading {
  padding-top: 20px;
  padding-bottom: 20px;
}
.page-heading h1 {
  font-size: 20px;
  font-weight: 400;
}
.page-heading p {
  font-size: 14px;
}

.plans-features {
  padding: 0 0 0 10px;
}
.plans-features li {
  margin-bottom: 7px;
  font-size: 14px;
}
.plans-features li .tick {
  color: green;
}
.plans-features li .close {
  color: red;
}
.best-plan {
  border-color: #0f62fe;
}
.plan-tag {
  min-height: 32px;
}
/* .drag-wrap {
  background: #fff;
}

.drag-wrap {
  background: #fff;
  border: 1px solid #0f62fe;
  padding: 15px;
  border-radius: 0;
}

.feature-item {
  padding: 10px;
  background: #f1f1f1;
  border-left: 3px solid #dbdbdb;
  cursor: move;
}
.feature-drag-sec .col:last-child .feature-item {
  background: #0f62fe;
  border-left: 3px solid #8db4ff;  
  color: #fff;
} */


.top-header {
	background: #4451a3;
}
.footer-links a {
    border-right: 1px solid #a9a9a9;
    display: inline-block;
    line-height: 1;
    margin-right: 5px;
    padding-right: 5px;
}
.footer-links a:last-child {
    border-right: 0;
}

/*Template Page Start*/

.temp-list-item {}

  .temp-img {
    min-height: 117px;
    box-shadow: 0px 0px 0px 1px #C6C6C6 inset;
    background: #F8F8F8;
	border-radius: 2px;	
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
	margin-bottom: 10px;
  }

  .temp-img .overflow {
    position: absolute;
  }

  .temp-list-item h6 {
    color: #161616;
    font-size: 14px;
    font-weight: 600;
  }
  
  .temp-list-item small {
    color: #525252;
    font-size: 12px;
}
.temp-list-item span.cds--overflow-menu__wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
}
.cds--tabs {
	position: relative;
}

.cds--tabs:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	height: 2px;
	background-color: #C6C6C6;
	z-index: 0;
}
.cds--tabs .cds--tabs__nav-item {
	z-index: 100;
}
.cds--tabs__nav-item--selected {border-block-end: 2px solid var(--cds-border-interactive, #4451A3);}

/*Template Page End*/

.cstm-flag-code {
	position: relative;
}
.cstm-flag-code select.cds--select-input {
    padding-left: 32px;
}
.cstm-flag-code img {
	position: absolute;
    width: 20px;
    bottom: 12px;
    left: 10px;
}
.arrow-icon .cds--modal-footer .cds--btn--primary {
    background-image: url('assets/images/next-arrow.png');
    background-repeat: no-repeat;
    background-position: right 20px top 18px;
}

.cds--toggle__switch--checked {
    background-color: #4451A3;
}

.upload-icon  {
    background-image: url('assets/images/cloud-upload.png');
    background-repeat: no-repeat;
    background-position: right 20px top 12px;
}
.cstm-btn-small {
    font-size: 12px;
    padding: 3px 5px;
}
.cds--btn--secondary {
    background-color: var(--cds-button-secondary, #474747);
}
.textarea-white textarea {
	background-color: #fff;
}
.manage-questions .actions {
	visibility: hidden;
	opacity: 0;
	transition: all 0.2s ease;
}
.manage-questions:hover .actions {
	visibility: visible;
	opacity: 1;
}


.expandable-table tr th:nth-child(1),
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(1) {width: 40px;}

.expandable-table tr th:nth-child(2),
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(2) {width: 52px;}

.expandable-table tr th:nth-child(3),
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(3) {width: 250px;}

.expandable-table tr th:nth-child(4),
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(4) {width: 150px;}

.expandable-table tr th:nth-child(5),
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(5) {width: 100px;}

.expandable-table tr th:nth-child(6),
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(6) {width: 100px;}

.expandable-table tr th:nth-child(7),
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(7) {width: 200px;}

.expandable-table tr th:nth-child(8),
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(8) {width: 100px;}

.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td {padding-left: 15px;padding-right: 15px;}
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(3) {
    padding-inline-start: 0;
    padding-inline-end: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	max-width: 250px;	
}
.expandable-table tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td tr td:nth-child(4) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	max-width: 150px;	
}
.icon-danger svg {
    fill: red;
}
.icon-success svg {
    fill: green;
}
.empty-table-body {
	block-size: 20rem !important;
}
.empty-table-body td {
	text-align: center;
    font-weight: bold;
}
.count-tag span {
    flex-shrink: 0;
}
/*
@media (max-width: 991px) {
	.sidebar {
		padding-bottom: 10px;
		position: fixed;
		top: 0;
		left: -250px;
		bottom: 0;
		overflow-y: auto;
		z-index: 999;
		transition: all 0.5s ease;
	}
	#wrapper #content-wrapper #content {
		padding: 10px;
	}
}*/